import { graphql } from 'gatsby'
import PageBecomeASitter from '../components/PageBecomeASitter'

export const query = graphql`
  query PageBecomeASitter {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default PageBecomeASitter
